<template>
  <activator :placeholder="`ابحث عن زبائن`"></activator>
</template>

<script>
import Activator from '@/EK-core/components/EK-dialog/activaitor.vue'

export default {
components:{
  Activator
}
}
</script>

<style>

</style>